import foster from "./FosterHomepage.module.css";

export default function FosterHomepage() {
    return (
        <div className={foster.page}>
            <div className={foster.containerWithItems}>
                <div className={foster.welcomebacktext}>
                    Foster Page
                </div>
            </div>
        </div>
    );
}
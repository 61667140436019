import adopt from "./AdoptHomepage.module.css";

export default function AdoptHomepage() {
    return (
        <div className={adopt.page}>
            <div className={adopt.containerWithItems}>
                <div className={adopt.welcomebacktext}>
                    Adopt Page
                </div>
            </div>
        </div>
    );
}
import Rehomepage from "./RehomeHomepage.module.css";

export default function RehomeHomepage() {
    return (
        <div className={Rehomepage.page}>
            <div className={Rehomepage.containerWithItems}>
                <div className={Rehomepage.welcomebacktext}>
                    Rehome Page
                </div>
            </div>
        </div>
    );
}
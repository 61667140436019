// src/rehomeApi.js

import { API_URL } from '../util/config';

export const createListing = async (
    userID, gender, description, name, type,
    breed, age, dob, weight, specialNeeds, pickupDate, foodReq, selectedFiles
) => {
    try {
        const formData = new FormData();
        formData.append('userID', userID);
        formData.append('gender', gender);
        formData.append('description', description);
        formData.append('name', name);
        formData.append('type', type);
        formData.append('breed', breed);
        formData.append('age', age);
        formData.append('dob', dob);
        formData.append('weight', weight);
        formData.append('specialNeeds', specialNeeds);
        formData.append('pickupDate', pickupDate);
        formData.append('foodReq', foodReq);
        console.log("??")
        console.log(selectedFiles)
        selectedFiles.forEach((image, index) => {
            formData.append(`images`, image);
            console.log(`Image ${index} appended`);
        });

        const response = await fetch(`${API_URL}/rehome`, {
            method: 'POST',
            body: formData,
            credentials: 'include',
        });

        if (!response.ok) {
            console.error(response);
            return false;
        }

        return true;
    } catch (error) {
        console.error('Error creating listing:', error);
        return false;
    }
};

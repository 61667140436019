import React, { useState, useEffect } from "react";
import { TextField, Button, Stack, Avatar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import viewAccount from "./ViewAccount.module.css";
import { useUser } from "../../context/UserContext";
import {logoutUser, refreshToken} from "../../api/authApi";
import { getAccountDetails, updateProfilePicture } from "../../api/userApi";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";

export default function ViewAccountPage() {
    const { user, setUser } = useUser();
    const [userData, setUserData] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [error, setError] = useState(null);
    const [openDialog, setOpenDialog] = useState(false); // For confirmation dialog
    const navigate = useNavigate();

    useEffect(() => {
        const checkToken = async () => {
            const res = await refreshToken();
            setUser(res.user);
            if (res.user === undefined) {
                navigate("../../login");
            } else {
                const userDetails = await getAccountDetails(res.user.userID);
                setUserData(userDetails);
                setImagePreview(userDetails.profilePicture);
            }
        };

        checkToken();
    }, [setUser, navigate]);

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        if (file) {
            const img = new Image();
            img.onload = () => {
                if (img.width > 1024 || img.height > 1024) {
                    setError("Image dimensions should not exceed 1024x1024 pixels.");
                    setSelectedFile(null);
                    setImagePreview(null);
                } else {
                    setSelectedFile(file);
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        setImagePreview(reader.result);
                    };
                    reader.readAsDataURL(file);
                    setError(null);
                }
            };
            img.src = URL.createObjectURL(file);
        }
    };

    const handleAvatarClick = () => {
        // Check if user already has a profile picture
        if (user.profilePicture) {
            setOpenDialog(true);
        } else {
            document.getElementById("fileInput").click();
        }
    };

    const handleUploadClick = async () => {
        if (selectedFile) {
            try {
                await updateProfilePicture(selectedFile);
                

            } catch (error) {
                console.error("Image upload failed:", error);
            }
            const res = await refreshToken();
            setUser(res.user);
        }
    };

    const handleDialogClose = (confirm) => {
        setOpenDialog(false);
        if (confirm) {
            document.getElementById("fileInput").click();
        }
    };

    const handleSignUpFormClick = async () => {
        navigate("../../updateAccount");
    };

    const handleCurrentListingClick = async () => {
        // Add navigation logic for current listings
    };

    const handlePastListingClick = async () => {
        // Add navigation logic for past listings
    };

    const handleLogOutClick = async () => {
        await logoutUser();
        // navigate('/');
        setTimeout(() => {
            setUser(null);
            navigate("/");
        }, 2000);
    }

    const [state, setState] = useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'left',
    });

    const { vertical, horizontal, open } = state;

    const handleClickAlert = () => {
        console.log("Should hit here")
        setState({ ...state, open: true });
    };

    const handleCloseAlert = () => {
        setState({ ...state, open: false });
    };

    /**
     * 0 - Active
     * 1 - Active in Grace Period
     * 2 - Deactivated
     * 3 - Deleted
     **/
    function getAccountStatus() {
        const status = userData.status;
        console.log("status ===" + userData.status);
        switch (status) {
            case 0:
                return "Active";
            case 1:
                return "Active in Grace Period";
            case 2:
                return "Deactivated";
            case 3:
                return "Deleted";
        }
    }

    return (
        <div className={viewAccount.page}>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
            </style>
            <div className={viewAccount.containerWithItems}>
                <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={open}
                    onClose={handleCloseAlert}
                    message="You have been logged out"
                    key={vertical + horizontal}
                />
                <div className={viewAccount.containersignin}>
                    <div className={viewAccount.signintext}>View Account</div>
                    <Stack direction="row" spacing={6} alignItems="center">
                        <Avatar
                            alt={userData.name}
                            src={imagePreview}
                            sx={{ width: 100, height: 100, cursor: "pointer" }}
                            onClick={handleAvatarClick}
                        />
                        <input
                            id="fileInput"
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={handleFileSelect}
                        />
                        {selectedFile && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleUploadClick}
                            >
                                Upload
                            </Button>
                        )}
                    </Stack>
                    {error && <p style={{ color: 'red' }}>{error}</p>}

                    <Stack direction="row" spacing={6}>
                        <div className={viewAccount.viewAcc}>
                            <div>Name:</div>
                            <div>Username:</div>
                            <div>Email:</div>
                            <div>Phone number:</div>
                            <div>Account Type:</div>
                            <div>Valid Account:</div>
                            {userData.description !== "" ? <div>Description:</div> : ""}
                        </div>
                        <div className={viewAccount.viewAccDetails}>
                            <div>{userData.name}</div>
                            <div>{userData.username}</div>
                            <div>{userData.email}</div>
                            <div>{userData.phoneNumber}</div>
                            <div>{userData.accountType === 1 ? "Private Account" : "Organization"}</div>
                            <div>{getAccountStatus()}</div>
                            {userData.description !== "" ? <div>{userData.description}</div>: ""}
                        </div>
                    </Stack>

                    <div className={viewAccount.center}>
                        <Stack direction="row" spacing={2}>
                            <Button
                                className={viewAccount.editAccountButton}
                                sx={{
                                    backgroundColor: '#89B0AE'
                                }}
                                variant="contained"
                                onClick={handleCurrentListingClick}>
                                CURRENT LISTINGS
                            </Button>
                            <Button
                                className={viewAccount.editAccountButton}
                                sx={{
                                    backgroundColor: '#89B0AE'
                                }}
                                variant="contained"
                                onClick={handlePastListingClick}>
                                PAST LISTINGS
                            </Button>
                            <Button
                                className={viewAccount.editAccountButton}
                                sx={{
                                    backgroundColor: '#89B0AE'
                                }}
                                variant="contained"
                                onClick={handleSignUpFormClick}>
                                EDIT ACCOUNT
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: '#FFD6BA'
                                }}
                                onClick={() =>{
                                    handleLogOutClick();
                                    handleClickAlert({ vertical: 'bottom', horizontal: 'center' });
                                }}
                            >
                                Log Out
                            </Button>
                        </Stack>
                    </div>
                </div>
            </div>

            {/* Confirmation Dialog */}
            <Dialog
                open={openDialog}
                onClose={() => handleDialogClose(false)}
            >
                <DialogTitle>Confirm Action</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You already have a profile picture. Do you want to replace it?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDialogClose(false)}>Cancel</Button>
                    <Button onClick={() => handleDialogClose(true)} color="primary">
                        Yes, Replace
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

import Found from "./FoundHomepage.module.css";

export default function FoundHomepage() {
    return (
        <div className={Found.page}>
            <div className={Found.containerWithItems}>
                <div className={Found.welcomebacktext}>
                    Found Page
                </div>
            </div>
        </div>
    );
}
// src/userApi.js

import { API_URL } from '../util/config';

export const checkUsernameAvail = async (username) => {
    try {
        const response = await fetch(`${API_URL}/user/username/${username}`, {
            method: 'GET',
        });
        return !(response.ok);
    } catch (error) {
        throw error;
    }
}

export const checkPhoneAvail = async (phone) => {
    try {
        const response = await fetch(`${API_URL}/user/phone/${phone}`, {
            method: 'GET',
        });
        return !(response.ok);
    } catch (error) {
        throw error;
    }
}

export const checkEmailAvail = async (email) => {
    try {
        const response = await fetch(`${API_URL}/user/email/${email}`, {
            method: 'GET',
        });
        return !(response.ok);
    } catch (error) {
        throw error;
    }
}

export const createAccount = async (name, username, password, email, phone, accountType) => {
    try {
        const response = await fetch(`${API_URL}/user`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    name: name,
                    username: username,
                    password: password,
                    email: email,
                    accountType: accountType === "privateUser" ? 1 : 2,
                    phoneNumber: phone
                }),
            credentials: 'include',
        });

        // if (!response.ok) {
        //     console.error(response);
        //     return null;
        // }
        console.log(response)
        return response;
    } catch (error) {
        throw error;
    }
}

export const getAccountDetails = async (userID) => {
    try {
        const response = await fetch(`${API_URL}/user/${userID}`, {
            method: 'GET',
            credentials: 'include',
        });

        if (!response.ok) {
            console.error(response);
            return null;
        }

        return response.json();
    } catch (error) {
        throw error;
    }
}

export const deleteAccount = async (userID) => {
    try {
        const response = await fetch(`${API_URL}/user/${userID}`, {
            method: 'DELETE',
            credentials: 'include',
        });

        if (!response.ok) {
            console.error(response);
            return null;
        }

        return response;
    } catch (error) {
        throw error;
    }
}

export const updateAccount = async (userID, details) => {
    try {
        const response = await fetch(`${API_URL}/user/${userID}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(details),
            credentials: 'include',
        });

        if (!response.ok) {
            console.error(response);
            return null;
        }

        return response;
    } catch (error) {
        throw error;
    }
}

export const requestPasswordReset = async (email) => {
    try {
        const response = await fetch(`${API_URL}/user/resetpassword/request`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    email: email
                }),
            credentials: 'include',
        });

        if (!response.ok) {
            console.error(response);
            return null;
        }

        return response;
    } catch (error) {
        throw error;
    }
}

export const resetPassword = async (verificationid, password) => {
    try {
        const response = await fetch(`${API_URL}/user/resetpassword/${verificationid}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    password: password
                }),
            credentials: 'include',
        });

        if (!response.ok) {
            const data = await response.json();
            alert(data.error);
            console.error(response);
            return null;
        }
        alert("Password reset successfully")
        return response;
    } catch (error) {
        throw error;
    }
}

export const checkUserVerification = async () => {
    try {
        const response = await fetch(`${API_URL}/user/userverification/check`, {
            method: 'GET',
            credentials: 'include',
        });

        if (!response.ok) {
            console.error(response);
            return null;
        }

        return response.json();
    } catch (error) {
        throw error;
    }
}

export const requestNewUserVerification = async () => {
    try {
        const response = await fetch(`${API_URL}/user/userverification/request`, {
            method: 'GET',
            credentials: 'include',
        });

        if (!response.ok) {
            console.error(response);
            return null;
        }

        return response;
    } catch (error) {
        throw error;
    }
}

export const updateProfilePicture = async (image) => {
    try {
        const form = new FormData();
        form.append('image', image);

        const response = await fetch(`${API_URL}/user/profilepicture`, {
            method: 'POST',
            body: form,
            credentials: 'include',
        });

        if (!response.ok) {
            console.error(response);
            return null;
        }

        return response.json();
    } catch (error) {
        throw error;
    }
}

// src/api/userApi.js

export const uploadOrganizationVerification = async (formData) => {
    try {
        const response = await fetch(`${API_URL}/user/organizationverification`, { // Use the correct API endpoint
            method: 'POST',
            body: formData,
            credentials: 'include',
        });

        if (!response.ok) {
            console.error('Error uploading files:', response.statusText);
            return null;
        }

        return await response.json();
    } catch (error) {
        console.error('Error uploading organization verification:', error);
        throw error;
    }
};

export const checkOrganizationVerification = async () => {
    try {
        const response = await fetch(`${API_URL}/user/organizationverification/check`, {
            method: 'GET',
            credentials: 'include',
        });

        console.log(response)
        if (!response.ok) {
            console.error('Error checking organization verification:', response.statusText);
            return null;
        }

        return await response.json();
    } catch (error) {
        console.error('Error checking organization verification:', error);
        throw error;
    }
}
import homepagecss from "./HomePage.module.css";
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import PersonIcon from '@mui/icons-material/Person';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import HandshakeIcon from '@mui/icons-material/Handshake';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useNavigate } from "react-router-dom";
import React, { useLayoutEffect, useState } from 'react';


export default function HomePage() {
    const navigate = useNavigate();

    const navigateAdoptPage = () => {
        navigate("../adoptHomepage");
    };

    const navigateFosterPage = () => {
        navigate("../fosterHomepage");
    };

    const navigateLostPetsPage = () => {
        navigate("../lostPetsHomepage");
    };

    const navigateFoundPage = () => {
        navigate("../FoundHomepage");
    };

    const navigateRehomePage = () => {
        navigate("../rehomeHomepage");
    };

    return (
        <div className={homepagecss.homepage}>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap" rel="stylesheet');
            </style>
            <button className={homepagecss.logoButton} onClick={event =>  window.location.href='/homepage'}>
                <div className={homepagecss.logoItem}>
                    PAWS
                </div>
                <
                    img src = "paws-4-your-home-logo.png" 
                    alt="Logo Image"
                    className={homepagecss.logoImage}
                />
                <div className={homepagecss.logoItem}>
                    YOUR HOME
                </div>
            </button>
            <div className={homepagecss.container}>
                <div className={homepagecss.secondContainer}>
                    <div className={homepagecss.chooseOption}>
                        <div className={homepagecss.centerText}>
                            WHAT DO YOU WANT TO DO?
                        </div>
               
                        <div  className={homepagecss.containerforicon}>
                            <div className={homepagecss.optionContainer}>                      
                                <button 
                                    className={homepagecss.optionButton}
                                    onClick={navigateAdoptPage}
                                >
                                    <HandshakeIcon sx={{width: 60, height: 60}}>
                                    </HandshakeIcon>
                                    <div className={homepagecss.logotext}>
                                        <div className={homepagecss.textContainer}>
                                            ADOPT
                                        </div>
                                    </div>
                                </button>
                            </div>  
                            <div className={homepagecss.optionContainer}>     
                                <button 
                                    className={homepagecss.optionButton}
                                    onClick={navigateFosterPage}
                                >
                                    <PersonIcon sx={{width: 60, height: 60}}>
                                    </PersonIcon>
                                    <div className={homepagecss.logotext}>
                                    FOSTER
                                    </div>
                                </button>
                            </div>
                            <div className={homepagecss.optionContainer}>     
                                <button 
                                    className={homepagecss.optionButton}
                                    onClick={navigateLostPetsPage}
                                >
                                    <SearchRoundedIcon sx={{width: 60, height: 60}}>
                                    </SearchRoundedIcon>
                                    <div className={homepagecss.logotext}>
                                        LOST PETS
                                    </div>
                                </button>
                            </div>
                        
                            <div className={homepagecss.optionContainer}>     
                                <button 
                                    className={homepagecss.optionButton}
                                    onClick={navigateFoundPage}
                                >
                                    <LocationOnIcon sx={{width: 60, height: 60}}>
                                    </LocationOnIcon>
                                    <div className={homepagecss.logotext}>
                                        FOUND
                                    </div>
                                </button>
                            </div>

                            <div className={homepagecss.optionContainer}>     
                                <button 
                                    className={homepagecss.optionButton}
                                    onClick={navigateRehomePage}
                                >
                                    <HomeRoundedIcon sx={{width: 60, height: 60}}>
                                    </HomeRoundedIcon>
                                    <div className={homepagecss.logotext}>
                                        REHOME
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
import React, { useState, useEffect } from "react";
import { TextField, Button, Select, InputLabel, FormControl, MenuItem, Alert } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import createAccountcss from "./CreateAccount.module.css";
import { checkEmailAvail, checkPhoneAvail, checkUsernameAvail, createAccount } from "../../api/userApi";
import { useNavigate } from "react-router-dom";

/** TODO:
 * - Connect front end to backend -- DONE
 * - Fix the buggy check of passwords -- DONE
 * - Fix email and username existence thing -- DONE
 * - Find a neater way to check if a field is empty or not -- DONE
 * - Replace "Finish Creating Account" with a "Create Account" button that is greyed out -- DONE
 * - Find a way to display the error messages in a neat manner
 * - Fix the CSS where "Create Account" is not overwritten by Full Name
 * - Fix bug where Confirm Password is always red until you click on Create Account button
 * - Find a way to remove errors when the state changes and before Create Account is clicked.
 * - Find a way to display an error message when the account fails to create.
 */


export default function CreateAccountPage() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [validEmail, setValidEmail] = useState(true);
    const [emailAvailable, setEmailAvailable] = useState(true);
    const [phone, setPhone] = useState("");
    const [validPhone, setValidPhone] = useState(true);
    const [phoneAvailable, setPhoneAvailable] = useState(true);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [username, setUsername] = useState("");
    const [usernameAvailable, setUsernameAvailable] = useState(true);
    const [name, setName] = useState("");
    const [accountType, setAccountType] = useState("");
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [createSuccess, setCreateSuccess] = useState(false);
    const [firstTime, setFirstTime] = useState(true);

    const handleEmailChange = async (event) => {
        const value = event.target.value;
        setEmail(value);

        const isValid = validateEmail(value);
        setValidEmail(isValid);

        if (isValid) {
            const available = await checkEmailAvail(value);
            setEmailAvailable(available);
        }
    };

    const handlePhoneNumberChange = async (event) => {
        const value = event.target.value;
        setPhone(value);

        const isValid = validatePhoneNumber(value);
        setValidPhone(isValid);

        if (isValid) {
            const available = await checkPhoneAvail(value);
            setPhoneAvailable(available);
        }
    };

    const handleUsernameChange = async (event) => {
        const value = event.target.value;
        setUsername(value);
        const available = await checkUsernameAvail(value);
        setUsernameAvailable(available);
    };

    const handlePasswordChange = (event) => {
        const value = event.target.value;
        setPassword(value);
        setPasswordMatch(value === confirmPassword);
    };

    const handleConfirmPasswordChange = (event) => {
        const value = event.target.value;
        setConfirmPassword(value);
        setPasswordMatch(value === password);
    };

    const validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    const validatePhoneNumber = (phone) => {
        const re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
        return re.test(phone);
    };

    // Form submission logic
    const handleLoginFormClick = async () => {
        if (usernameAvailable && emailAvailable && phoneAvailable && passwordMatch) {
            const result = await createAccount(name, username, password, email, phone, accountType);

            if (!result.ok) {
                console.error(result);
                
                // TODO: change this to expect proper json 
                // we will use ErrorManager in backend eventually
                
                let text = await result.text();
                alert(text);
            }
            setCreateSuccess(result.ok);

            if (result.ok) {
                setTimeout(() => {
                    navigate("/login");
                }, 5000);
            }
        }
    };

    return (
        <div className={createAccountcss.page}>
            <div className={createAccountcss.containerWithItems}>
                <div className={createAccountcss.welcomebacktext}>CREATE ACCOUNT</div>
                <div className={createAccountcss.containersignin}>
                    <TextField
                        label="Full Name or Organization Name"
                        variant="standard"
                        size="medium"
                        fullWidth
                        onChange={(event) => setName(event.target.value)}
                    />
                    <TextField
                        label="Username"
                        variant="standard"
                        size="medium"
                        fullWidth
                        onChange={handleUsernameChange}
                        error={!usernameAvailable}
                        helperText={!usernameAvailable && "Username not available"}
                    />
                    <TextField
                        label="Email"
                        variant="standard"
                        size="medium"
                        fullWidth
                        onChange={handleEmailChange}
                        error={!validEmail || !emailAvailable}
                        helperText={
                            !validEmail
                                ? "Invalid email format"
                                : !emailAvailable && "Email not available"
                        }
                    />
                    <TextField
                        label="Phone Number"
                        variant="standard"
                        size="medium"
                        fullWidth
                        onChange={handlePhoneNumberChange}
                        error={!validPhone || !phoneAvailable}
                        helperText={
                            !validPhone
                                ? "Invalid phone number format"
                                : !phoneAvailable && "Phone number not available"
                        }
                    />
                    <TextField
                        label="Password"
                        type="password"
                        variant="standard"
                        size="medium"
                        fullWidth
                        onChange={handlePasswordChange}
                    />
                    <TextField
                        label="Confirm Password"
                        type="password"
                        variant="standard"
                        size="medium"
                        fullWidth
                        onChange={handleConfirmPasswordChange}
                        error={!passwordMatch && !firstTime}
                        helperText={!passwordMatch && "Passwords do not match"}
                    />
                    <FormControl fullWidth>
                        <InputLabel id="account-type-select-label">Account Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={accountType}
                            label="Account Type"
                            onChange={(event) => setAccountType(event.target.value)}
                        >
                            <MenuItem value="adoptionCenter">Organization</MenuItem>
                            <MenuItem value="privateUser">Private User</MenuItem>
                        </Select>
                    </FormControl>

                    <Button
                        style={{ backgroundColor: "#555B6E" }}
                        variant="contained"
                        onClick={handleLoginFormClick}
                        disabled={!usernameAvailable || !emailAvailable || !phoneAvailable || !passwordMatch}
                    >
                        CREATE ACCOUNT
                    </Button>

                    {createSuccess && (
                        <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                            Account creation successful. Please check your inbox for a verification email. You will be redirected to the login page in 5 seconds.
                        </Alert>
                    )}
                </div>
            </div>
        </div>
    );
}

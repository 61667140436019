import React, { useState, useEffect, useRef } from "react";
import {
    TextField,
    Button,
    Radio,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel
} from "@mui/material";
import addPet from "./AddPet.module.css";
import { createListing } from "../../api/adoptFosterApi";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { refreshToken } from "../../api/authApi";
import { useUser } from "../../context/UserContext";

export default function AddPetPage() {
    const [name, setName] = useState("");
    const [age, setAge] = useState("");
    const [gender, setGender] = useState("");
    const [type, setType] = useState("");
    const [personality, setPersonality] = useState("");
    const [dob, setDob] = useState(dayjs('2024-09-16'));
    const [fee, setFee] = useState("");
    const [weight, setWeight] = useState("");
    const [breed, setBreed] = useState("");
    const [location, setLocation] = useState("");
    const [specialNeeds, setSpecialNeeds] = useState("");
    const [listingType, setListingType] = useState("");
    const [dataUsed, setDataUsed] = useState(false);
    const [validListing, setValidListing] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [createSuccess, setCreateSuccess] = useState(false);
    const today = dayjs();
    const { user, setUser } = useUser();
    const navigate = useNavigate();
    const fileInputRef = useRef(null); 
    const states = [name, age, weight, personality, gender, listingType];

    const handleFormClick = async () => {
        check();
        setDataUsed(true);
        if (validListing === true) {
            const formData = new FormData();
            formData.append('userID', user.userID);
            formData.append('gender', gender);
            formData.append('personality', personality);
            formData.append('name', name);
            formData.append('type', type);
            formData.append('breed', breed);
            formData.append('age', age);
            formData.append('dob', dob);
            formData.append('weight', weight);
            formData.append('specialNeeds', specialNeeds);
            formData.append('listingType', listingType);
            formData.append('location', location);
            formData.append('fee', fee);

            console.log(formData)
            selectedFiles.forEach(file => {
                formData.append('images', file);
            });

            const result = await createListing(formData);
            if (result) {
                setCreateSuccess(true);
                navigate("../../home");
            } else {
                setCreateSuccess(false);
                // make the buttons available again god please why do you even disable it
                setDataUsed(false);
            }
            
        }
    };

    const check = function () {
        let result = states.map(function (e) {
            if (e === "") {
                setValidListing(false);
                return false;
            }
            return true;
        });

        if (result.every(Boolean)) {
            setValidListing(true);
        } else {
            setValidListing(false);
        }
    };

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files); // Convert FileList to Array
        if (files.length > 0) {
            setSelectedFiles(files); // Store selected files
        } else {
            setSelectedFiles([]);
        }
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleAgeChange = (event) => {
        setAge(event.target.value);
    };

    const handlePersonalityChange = (event) => {
        setPersonality(event.target.value);
    };

    const handleSpecialNeedsChange = (event) => {
        setSpecialNeeds(event.target.value);
    };

    const handleWeightChange = (event) => {
        setWeight(event.target.value);
    };

    const handleBreedChange = (event) => {
        setBreed(event.target.value);
    };

    const handleLocationChange = (event) => {
        setLocation(event.target.value);
    };

    const handleTypeChange = (event) => {
        setType(event.target.value);
    };

    const handleFeeChange = (event) => {
        setFee(event.target.value);
    };

    const handleGenderChange = (event) => {
        setGender(event.target.value);
    };

    const handleListingTypeChange = (event) => {
        setListingType(event.target.value);
    };

    useEffect(() => {
        const checkToken = async () => {
            const res = await refreshToken();
            setUser(res.user);
            if (!res.user) {
                navigate("../../login");
            }
        };

        checkToken();
    }, [setUser, navigate]);

    return (
        <div className={addPet.page}>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
            </style>
            <div className={addPet.containerWithItems}>
                <div className={addPet.containerwithtext}>
                    <div className={addPet.title}>List Your Pet</div>

                    <div className={addPet.containerwithitemfill}>
                        <TextField label="Name" size="medium" fullWidth onChange={handleNameChange} disabled={dataUsed} required />
                        <TextField label="Age" size="medium" fullWidth onChange={handleAgeChange} disabled={dataUsed} required />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker label={"Birthday:"} views={['year', 'month', 'day']} defaultValue={today} value={dob} onChange={setDob} disabled={dataUsed} required />
                        </LocalizationProvider>
                        <TextField label="Gender" size="medium" fullWidth onChange={handleGenderChange} disabled={dataUsed} required />
                        <TextField label="Weight" size="medium" fullWidth onChange={handleWeightChange} disabled={dataUsed} required />
                        <TextField label="Type" size="medium" fullWidth onChange={handleTypeChange} disabled={dataUsed} required />
                        <TextField label="Breed" size="medium" fullWidth onChange={handleBreedChange} disabled={dataUsed} />
                        <TextField label="Fee" size="medium" fullWidth onChange={handleFeeChange} disabled={dataUsed} />
                        <TextField label="Personality" size="medium" fullWidth onChange={handlePersonalityChange} disabled={dataUsed} multiline rows={5} required />
                        <TextField label="Special Needs" size="medium" fullWidth onChange={handleSpecialNeedsChange} disabled={dataUsed} multiline rows={3} />
                        <TextField label="Location" size="medium" fullWidth onChange={handleLocationChange} disabled={dataUsed} multiline rows={3} />

                        <FormControl>
                            <FormLabel>Type of listing:</FormLabel>
                            <RadioGroup row onChange={handleListingTypeChange}>
                                <FormControlLabel value="adoption" control={<Radio />} label="Adoption" />
                                <FormControlLabel value="foster" control={<Radio />} label="Foster" />
                            </RadioGroup>
                        </FormControl>

                        {/* Image Upload */}
                        <div>
                            <Button component="label" variant="contained" disabled={dataUsed}>
                                Upload Images
                                <input type="file" ref={fileInputRef} hidden multiple accept="image/*" onChange={handleFileChange} />
                            </Button>
                            {selectedFiles.length > 0 && <div>{selectedFiles.length} file(s) selected</div>}
                        </div>

                    </div>
                    <div className={addPet.buttonSubmit}>
                        <Button style={{ backgroundColor: "#555B6E" }} variant="contained" onClick={handleFormClick}>SUBMIT</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

import lostPets from "./LostPetsHomepage.module.css";

export default function LostPetsHomepage() {
    return (
        <div className={lostPets.page}>
            <div className={lostPets.containerWithItems}>
                <div className={lostPets.welcomebacktext}>
                    Lost Pets Page
                </div>
            </div>
        </div>
    );
}
import React, { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useNavigate, useLocation } from "react-router-dom";
import { refreshToken, pingServer } from '../api/authApi'; 

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [user, setUser] = useState(null);

    useEffect(() => {   
        const checkServer = async () => {
            const result = await pingServer();
            console.log("Server is up: ", result);
            if (!result) {
                navigate('/serverdown');
            }
        }
        checkServer();
    } , []);
    
    useEffect(() => {
        const checkToken = async () => {
            const res = await refreshToken();
            setUser(res.user);
        };

        checkToken();
        console.log("UserContext: ", user);
    }, [setUser]);


    useEffect(() => {
        // can probably simplify since 4 only applies to orgs
        if (user && user.userID && user.status === 1 && location.pathname !== "/needverification" && user.accountType === 1) {
            navigate("/needverification");
        }
        if (user && user.userID && user.status === 1 && location.pathname !== "/needverification" && user.accountType === 2) {
            navigate("/needverification");
        }
        if (user && user.userID && user.status === 4 && location.pathname !== "/orgverify" && user.accountType === 2) {
            navigate("/orgverify");
        }
    }, [user, navigate, location.pathname]);

    const login = async (userData) => {
        return new Promise((resolve) => {
            setUser({ ...userData });
            if (userData && userData.userID && userData.status === 1 && location.pathname !== "/needverification" && user.AccountType === 1) {
                navigate("/needverification");
            }
            if (user && user.userID && user.status === 1 && location.pathname !== "/needverification" && user.AccountType === 2) {
                navigate("/orgverify");
            }
            resolve();
        });
    };

    const logout = () => {
        setUser(null);
        navigate('/login');
    };

    return (
        <UserContext.Provider value={{ user, login, logout, setUser }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    return useContext(UserContext);
};

import css from "./TestRoute.module.css";
import {Button } from "@mui/material";
import { useUser } from '../../context/UserContext';
import {useNavigate} from "react-router-dom";
import { protectedRoute } from "../../api/authApi";



export default function TestRoute() {
    const navigate = useNavigate();
    
    const handleClick = async () => {
        const response = await protectedRoute();
        console.log(response);
    }

    //Need to check that users need verification.

    return (
        <div className={css.page}>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap" rel="stylesheet');
            </style>
            <div className={css.container}>
                <div className={css.containerItem}>
                    Test Route 
                </div>
                <div className={css.ButtonAlign}>
                    <Button style={{backgroundColor:"#555B6E"}} variant="contained" onClick={handleClick}>Protected Route</Button>
                </div>
            </div>
           
 
        </div>
    );
} 
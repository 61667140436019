import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginPage from "./pages/LoginPage/LoginPage";
import CreateAccountPage from "./pages/CreateAccount/CreateAccount";
import RehomeListing from "./pages/RehomeListing/RehomeListing";
import HomePage from "./pages/HomePage/HomePage";
import UpdateAccountPage from "./pages/UpdateAccount/UpdateAccount";
import AddPetPage from "./pages/AddPet/AddPet";
import ViewAccountPage from "./pages/ViewAccount/ViewAccount";
import { UserProvider } from "./context/UserContext";
import Navbar from "./component/Navbar/Navbar";
import AdoptHomepage from "./pages/AdoptHomepage/AdoptHomepage";
import FosterHomepage from "./pages/FosterHomepage/FosterHomepage";
import LostPetsHomepage from "./pages/LostPetsHomepage/LostPetsHomepage";
import RehomeHomepage from "./pages/RehomeHomepage/RehomeHomepage";
import FoundHomepage from "./pages/FoundHomepage/FoundHomepage";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import ResetForgottenPassword from "./pages/ResetForgottenPassword/ResetForgottenPassword";
import NeedVerification from "./pages/Verification/NeedToVerify";
import OrganizationNeedVerification from "./pages/Verification/OrganizationVerification";
import TestRoute from "./pages/TestRoute/TestRoute";
import ServerDown from "./pages/ServerDown/ServerDown";

const App = () => {
    return (
        <UserProvider>
            <div className="app-container">
                <Navbar />
                <div className="app-content">
                    <Routes>
                        {/* Change routes here */}
                        <Route exact path="/" element={<HomePage />} />
                        <Route exact path="/home" element={<HomePage />} />
                        <Route exact path="/homepage" element={<HomePage />} />

                        <Route exact path="/login" element={<LoginPage />} />
                        <Route exact path="/createAccount" element={<CreateAccountPage />} />
                        <Route exact path="/rehome" element={<RehomeListing />} />
                        <Route exact path="/updateAccount" element={<UpdateAccountPage />} />
                        <Route exact path="/addPet" element={<AddPetPage />} />
                        <Route exact path="/viewaccount" element={<ViewAccountPage />} />

                        <Route exact path="/adoptHomepage" element={<AdoptHomepage />} />
                        <Route exact path="/fosterHomepage" element={<FosterHomepage />} />
                        <Route exact path="/lostPetsHomepage" element={<LostPetsHomepage />} />
                        <Route exact path="/rehomeHomepage" element={<RehomeHomepage />} />
                        <Route exact path="/foundHomepage" element={<FoundHomepage />} />
                        <Route exact path="/resetPassword" element={<ResetPassword />} />
                        <Route exact path="/resetpassword/:id" element={<ResetForgottenPassword />} />
                        <Route exact path="/needverification" element={<NeedVerification />} />
                        <Route exact path="/orgverify" element={<OrganizationNeedVerification/>} />
                        <Route exact path="/serverdown" element={<ServerDown />} />
                        <Route exact path="/test" element={<TestRoute />} />
                    </Routes>
                </div>
            </div>
        </UserProvider >
    );
};


export default App;

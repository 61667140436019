// src/adoptFosterApi.js

import { API_URL } from '../util/config';

export const createListing = async (formData) => {
    try {
        const response = await fetch(`${API_URL}/adopt`, {
            method: 'POST',
            body: formData,
            credentials: 'include',
        });

        if (!response.ok) {
            console.error(response);
            return false;
        }

        return true;
    } catch (error) {
        throw error;
    }
};